import {UserListDetailsTO} from 'api/entities';
import {Subject} from 'rxjs';
import {Client, UserSession} from './avelon-user.interface';

let selectedClient: Client = null;

export const selectedClientChange$ = new Subject<void>();

export const Alcedo7User: UserSession = {
  clients: [],
  isIoT: false,
  currentUser: null,
  get selectedClient(): Client {
    return selectedClient;
  },
  set selectedClient(client: Client) {
    selectedClient = client;
    selectedClientChange$.next();
  },
  token: ''
};

export function getUserName(user: UserListDetailsTO): string {
  return user.firstName ? user.firstName + ' ' + user.lastName : user.lastName;
}

export function updateUserName(user: {firstName?: string; lastName?: string; name?: string}): {firstName?: string; lastName?: string; name?: string} {
  user.name = getUserName(user);
  return user;
}
